import { useCallback, useEffect, useState } from "react";
import { isCheckpointDomain } from "../../data/isCheckpointDomain";
import { useInterval } from "react-use";
import {
  checkSubscriptionStatus,
  SubscriptionStatus,
  validateTenantSKU,
  ValidateTenantSKUs,
} from "../../tools/cp-middleware-adapter";
import { useRequest } from "../../hooks";
import { isValidRegionForSaaSApi } from "../../data/constants";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../store/api/cpCheckTenantGet/cpCheckTenantGetActions";
import {
  requestFailure,
  setTenantSKUs,
} from "../../store/api/cpCheckTenantSKUs/cpCheckTenantSKUsGetActions";
import { ApplicationName } from "types/CPIPAppIntegration";

const MAX_SUBSCRIPTION_POLLING_RETRIES = 22;
const usePollSubscriptionStatus = () => {
  const [{ loading }, sendSubscriptionStatusReq] = useRequest(
    checkSubscriptionStatus
  );
  const [{}, sendValidateTenantSKU] = useRequest(validateTenantSKU);
  const { userData } = useSelector((s: any) => s.cpCheckTenantGet);
  const [subscriptionStatus, setSubscriptionStatus] = useState<{
    status: SubscriptionStatus;
    retries: number;
  }>({ status: undefined, retries: 0 });
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      isCheckpointDomain &&
      isValidRegionForSaaSApi(process.env.REACT_APP_REGION)
    ) {
      const SKUValidation = async () => {
        const payload = {
          appList: [ApplicationName.SaasApi],
        };
        try {
          const { data } = await sendValidateTenantSKU(payload);
          const tenantSKUs = {};
          const { appList } = payload;
          appList.forEach((app, index) => {
            tenantSKUs[app] = data.checkRes[index];
          });
          dispatch(setTenantSKUs(tenantSKUs as ValidateTenantSKUs));
        } catch (error) {
          dispatch(requestFailure(error.message));
        }
      };
      SKUValidation();
    }
  }, [dispatch, sendValidateTenantSKU]);

  const getSubscriptionStatus = useCallback(async () => {
    if (loading) {
      return;
    }

    try {
      const { data } = await sendSubscriptionStatusReq();
      setSubscriptionStatus((state) => ({
        status: data?.status || SubscriptionStatus.FAIL,
        retries: state.retries + 1,
      }));
      if (data.status === SubscriptionStatus.SUCCESS) {
        dispatch(
          setUserData({
            ...userData,
            tenantExists: true,
            userExists: true,
            chargebeeLicenseExists: true,
            checkpointLicenseExists: true,
            isCreatingNewWorkspace: false,
          })
        );
      }
    } catch (e) {
      setSubscriptionStatus((state) => ({
        ...state,
        status: SubscriptionStatus.FAIL,
      }));
    }
  }, [dispatch, loading, sendSubscriptionStatusReq, userData]);

  useEffect(() => {
    if (subscriptionStatus.retries >= MAX_SUBSCRIPTION_POLLING_RETRIES) {
      setSubscriptionStatus((state) => ({
        ...state,
        status: SubscriptionStatus.FAIL,
      }));
    }
  }, [subscriptionStatus.retries]);

  const shouldPollForSubscriptionStatus = userData?.isCreatingNewWorkspace
    ? 1000
    : null;

  useInterval(getSubscriptionStatus, shouldPollForSubscriptionStatus);

  return subscriptionStatus;
};

export default usePollSubscriptionStatus;
